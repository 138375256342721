import { ChangeDetectionStrategy, Component, Input, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ModalAction,
  ModalStep,
} from '@shared/components/hub-entity-action-modal/hub-entity-action-modal';

@Component({
  selector: 'app-hub-entity-action-modal',
  templateUrl: './hub-entity-action-modal.component.html',
  styleUrls: ['./hub-entity-action-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HubEntityActionModalComponent {
  @Input() steps: ModalStep[] = [];
  @Input() stopPropagation?: boolean;

  stepIndex = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private domSanitizer: DomSanitizer,
  ) {}

  onActionBtnClick(event: Event, action: ModalAction): void {
    if (this.stopPropagation) {
      event.stopPropagation();
    }

    switch (action) {
      case ModalAction.cancel: {
        this.onClose();
        break;
      }

      case ModalAction.nextStep: {
        if (this.stepIndex + 1 <= this.steps.length) {
          this.stepIndex += 1;
        }
        break;
      }

      case ModalAction.apply: {
        this.onClose(true);
        break;
      }
    }
  }

  sanitize(text: string): string {
    return this.domSanitizer.sanitize(SecurityContext.HTML, text) || '';
  }

  private onClose(applyActionOnEntity = false): void {
    this.activeModal.close(applyActionOnEntity);
  }
}
