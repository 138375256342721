export enum ModalAction {
  cancel = 'CANCEL',
  nextStep = 'NEXT_STEP',
  apply = 'APPLY',
}

export interface ModalStep {
  title: string;
  paragraphs: string[];
  actionsButtons: ModalActionButton[];
}

export interface ModalActionButton {
  label: string;
  action: ModalAction;
  actionClass?: string;
}
