@if (steps!.length > 0) {
  <div (click)="$event.stopPropagation()">
    <div class="modal-header">
      @if (steps[stepIndex].title) {
        <p
          class="hub-fs-20 hub-ff-krub hub-mb-0 hub-text-lh-120"
          [innerHTML]="sanitize(steps[stepIndex].title)"></p>
      }
    </div>
    <div class="modal-body hub-mb-0-25">
      @for (paragraph of steps[stepIndex].paragraphs; track paragraph) {
        @if (paragraph) {
          <p
            class="hub-text-lh-1-5 hub-ff-krub hub-fs-16 hub-m-0"
            [innerHTML]="sanitize(paragraph)"></p>
        }
      }
    </div>

    <div
      class="modal-footer hub-d-flex hub-justify-content-end hub-mt-auto hub-outline-0"
      tabindex="0">
      @for (btn of steps[stepIndex].actionsButtons; track btn; let i = $index) {
        <button
          type="button"
          class="btn hub-mr-1 {{ btn.actionClass ? btn.actionClass : 'btn-secondary' }}"
          (click)="onActionBtnClick($event, btn.action)">
          {{ btn.label }}
        </button>
      }
    </div>
  </div>
}
